import WebApp from 'tma-dev-sdk';
import { model, useDirectives } from 'solid-utils/model';
import { createHistorySignal } from 'solid-utils/history';
import { get, set } from 'solid-utils/access';
import { For, Show, Suspense, batch, createEffect, createMemo, createRenderEffect, createSelector, createSignal, createUniqueId, getOwner, lazy, on, onCleanup, onMount, runWithOwner, useContext } from 'solid-js';
import type { ParentProps, Signal } from 'solid-js';
import { useNavigate, useParams } from '@solidjs/router';
import { MultiProvider } from '@solid-primitives/context';

import { getMessageLink, isInline, isMac, isMobile, setFrameColor } from 'shared/platform';
import { parallel } from 'shared/network/utils';
import { resolvedBackendUrl } from 'shared/network/base-client';
import { Marker, memoize } from 'shared/memoize';
import { dateToCron, detectRepeats } from 'shared/l10n/cron';
import type { RepetitionType } from 'shared/l10n/cron';

import { deleteTask, uncompleteTask, completeTask, postToChat } from 'f/task/task.network';
import { BaseClientTask, FullClientTask, TaskType } from 'f/task/task.adapter';
import { hasNoTime, hasTime, isDateInPast, today, tomorrow, withTime } from 'f/settings/units/date';
import { UsersContext, createUsersResource } from 'f/project/users.context';
import { ClientUser, ClientUserStatus } from 'f/project/users.adapter';
import { ProjectContext, defaultProject } from 'f/project/project.context';
import { isArea, ProjectType } from 'f/project/project.adapter';
import type { ClientItem } from 'f/project/project.adapter';
import { ProfileContext } from 'f/profile/profile.context';
import { Permissions } from 'f/profile/profile.adapter';
import { createGroupTreeResource, GroupTreeContext } from 'f/group/list.context';

import { FullTaskContext } from '../task.context';
import { PreviewContext } from '../files/preview/context';
import type { FilePreviewController } from '../files/preview/context';
import PreviewHost from '../files/preview';
import { attachFiles, deleteFiles } from '../files/network';
import { ClientTaskFile } from '../files/adapter';
import type { DisplayableFile } from '../files/adapter';

import { linkRegexp, useLinkMarker } from './markers/link';
import { checkRegexp, useChecklistMarker } from './markers/checklist';
import { HistoryList } from './history';
import { FilesList, formFieldName, getFormFiles } from './files';

import { t } from 'locales/task';
import { t as tGroup } from 'locales/group';
import { t as tButton } from 'locales/create-button';

import { useDialog } from 'shared/ui/use-dialog';
import { MainButton } from 'shared/ui/telegram';
import ProBadge from 'shared/ui/pro-badge';
import { Loader } from 'shared/ui/loader.ui';
import ListArrow from 'shared/ui/list-arrow';
import List from 'shared/ui/list';
import { InitialsAvatar } from 'shared/ui/initials-avatar';
import Dialog from 'shared/ui/dialog';
import Checkbox from 'shared/ui/checkbox';

import { UserSelect } from 'f/task/user-select.ui';
import { ProjectSelect } from 'f/task/project-select.ui';
import { PlanDateSelect } from 'f/task/plan-date-select.ui';
import Header from 'f/task/header.ui';
import { DueDateSelect } from 'f/task/due-date-select.ui';
import { ItemIcon } from 'f/group/explorer-item.ui';

import Telegram from 'icons/list/Telegram.png';
import Trash from 'icons/Trash.svg';
import ReporterOutlined from 'icons/ReporterOutlined.svg';
import Repeat from 'icons/Repeat.svg';
import Project from 'icons/Project.svg';
import Pro from 'icons/Pro.svg';
import ListIcon from 'icons/List.svg';
import FlagFilled from 'icons/FlagFilled.svg';
import Flag from 'icons/Flag.svg';
import ChevronCheck from 'icons/ChevronCheck.svg';
import Checkmark from 'icons/Checkmark.svg';
import Chat from 'icons/Chat.svg';
import Calendar from 'icons/Calendar.svg';
import AssigneeOutlined from 'icons/AssigneeOutlined.svg';

export interface CreateTaskProps {
  task?: undefined;
  setTask(task: BaseClientTask, projectId?: string): Promise<readonly [() => void, undefined | FullClientTask]>;
}

export interface EditTaskProps {
  task: FullClientTask;
  setTask(task: FullClientTask, local?: boolean): Promise<readonly [() => void, undefined | FullClientTask]>;
}

export default function TaskEditor(props: CreateTaskProps | EditTaskProps) {
  onMount(() => {
    setFrameColor('secondary_bg_color');
  });

  useDirectives(model);

  const groupTree = useContext(GroupTreeContext) ?? createGroupTreeResource();

  const lineHeight = 1.25 * 16;
  // 6 subheadline text lines
  const minSetDescriptionHeight = lineHeight * 6;

  const [source] = useContext(ProjectContext);

  const title = createHistorySignal('');
  const description = createHistorySignal('');
  const isCompleted = createHistorySignal(false);
  const dueDate = createHistorySignal<Date | null>(null);
  const planDate = createHistorySignal<Date | null>(null);
  const planCron = createHistorySignal<string>();
  const files = createHistorySignal<ClientTaskFile[]>([]);
  const selectedRepetition = createHistorySignal<RepetitionType>();
  const assignees = createHistorySignal<ClientUser[]>([]);
  const author = createHistorySignal<ClientUser[]>([]);
  const project = createHistorySignal<ClientItem>(
    detectInitialProject(),
  );

  function detectInitialProject() {
    return source.latest?.type === ProjectType.Dynamic
      ? isArea(source.latest) ? getAreaRelatedProject() ?? defaultProject : defaultProject
      : source.latest;
  }

  function getAreaRelatedProject() {
    const areas = groupTree[0].latest.areas;
    const area = areas.find(a => a.id === source.latest.id);
    return area?.items[0];
  }

  const contact = new ClientUser({
    title: 'Telegram Contact',
    userName: 'Telegram Contact',
  });

  contact.avatar = Telegram;

  const currentContact = createHistorySignal<ClientUser[]>([contact]);

  const selectedProjectUsers = createUsersResource(
    () => get(project).type === ProjectType.Public ? get(project).id : undefined,
    useContext(UsersContext)?.[0],
  );

  const [currentProjectUsers] = useContext(UsersContext) ?? [];

  const hasCompletedTask = memoize(
    (assignee?: ClientUser) => props.task?.completedUsers?.includes(assignee?.userId ?? -1),
    (assignee?: ClientUser) => assignee?.userId ?? assignee?.title ?? createUniqueId(),
  );

  const hasSomeCompletedUsers = createMemo(() => (props.task?.completedUsers?.length ?? 0) > 0);

  createEffect(on(() => [props.task, source.latest] as const, ([task, newProject]) => {
    batch(() => {
      title.reset(task?.title ?? title.original);
      description.reset(task?.description ?? description.original);
      files.reset(task?.files ?? []);
      isCompleted.reset((task?.isCompleted ?? isCompleted.original) || (task?.completedUsers?.some(id => id === WebApp.initDataUnsafe?.user?.id) ?? isCompleted.original));
      assignees.reset(task?.coassignees ?? assignees.original);
      author.reset(task?.author ? [task.author] : author.original);
      project.reset(
        (newProject === defaultProject || newProject.type === ProjectType.Dynamic)
          ? task?.project ?? project.original
          : newProject,
      );
      dueDate.reset(task?.dueDate ?? dueDate.original, true);
      planDate.reset(initialDate(), true);
      planCron.reset(task?.planCron, true);
      if (task?.planCron) {
        selectedRepetition.reset(detectRepeats(task.planCron));
      }
    });
  }));

  const [isRequestInProgress, setRequestInProgress] = createSignal(false);
  const [isDeleting, setIsDeleting] = createSignal(false);
  const [profile] = useContext(ProfileContext);

  const canUseFiles = () => profile.latest?.canUseAll([Permissions.FileSize, true], [Permissions.FilesAmount, true]);
  const maxFileSize = () => profile.latest?.getPermission(Permissions.FileSize, true);
  const maxFileAmount = () => profile.latest?.getPermission(Permissions.FilesAmount, true);
  const canUseDueDate = () => profile.latest?.canUse(Permissions.DueDate);

  const owner = getOwner();
  const navigate = useNavigate();

  const deleteTaskOnClick = () => {
    if (!props.task?.id) {
      return;
    }

    setIsDeleting(true);

    deleteTask(props.task?.id)
      .then(() => navigateBack())
      .catch(() => WebApp.showAlert(t('delete error')))
      .finally(() => setIsDeleting(false));
  };

  createEffect(() => {
    if (isRequestInProgress()) {
      WebApp.enableClosingConfirmation();
    } else {
      WebApp.disableClosingConfirmation();
    }
  });

  const setTaskCompletionFailed = () => {
    set(isCompleted, false);
    WebApp.HapticFeedback.notificationOccurred('error');
  };

  const [isCompletionInProgress, setCompletionInProgress] = createSignal(false);

  let timeoutHandle: number;

  // TODO: refactor this
  const submitTaskComplete = (e: MouseEvent) => {
    e?.stopPropagation();

    const taskId = props.task?.id;

    if (!taskId || isCompletionInProgress()) {
      return;
    }

    clearTimeout(timeoutHandle);

    setCompletionInProgress(true);

    const result = submitTask(false)
      .then(() => (
        !get(isCompleted)
        ? uncompleteTask(taskId)
        : completeTask(taskId)
    ));

    result.then((r) => {
      if (r.error) {
        setTaskCompletionFailed();
        return;
      }

      if (r.data && props.task) {
        props.setTask(r.data, true);
      }

      if (r.data?.isCompleted ?? get(isCompleted)) {
        navigateBack();
      }
    })
    .catch(() => {
      setTaskCompletionFailed();
    })
    .finally(() => {
      setCompletionInProgress(false);
    });
  };

  const formData = new FormData();

  const isFormDisabled = () => {
    const participants = [...props.task?.coassignees ?? [], props.task?.author].filter(x => !!x);
    const currentUserId = WebApp.initDataUnsafe.user?.id;

    return get(isCompleted)
      || props.task?.isCompleted
      || isRequestInProgress()
      || (props.task && !participants.some(u => currentUserId === u?.userId) && !isAdmin());
  };

  const onScroll = () => requestAnimationFrame(() => {
    (document.activeElement as HTMLElement).blur?.();
    removeEventListener('scroll', onScroll);
  });

  onMount(() => {
    addEventListener('scroll', onScroll);
  });

  onCleanup(() => {
    removeEventListener('scroll', onScroll);
  });

  const expandedDescription = createSignal(false);

  const descriptionHeight = createSignal(lineHeight);

  const postTaskToChat = () => {
    setRequestInProgress(true);
    const taskId = props.task?.id ?? useParams().taskId;

    postToChat(taskId)
      .then((inviteLink: string | number | undefined) => {
        const chatId = props.task?.project?.chatId;

        if (!isNaN(Number(inviteLink)) && chatId) {
          inviteLink = getMessageLink({
            messageId: Number(inviteLink),
            chatId,
          });
        } else if (chatId && props.task?.lastMessageId) {
          inviteLink = getMessageLink({
            messageId: props.task.lastMessageId,
            chatId,
          });
        }

        if (inviteLink) {
          WebApp.openTelegramLink(String(inviteLink));
        } else {
          WebApp.close();
        }
      })
      .finally(() => setRequestInProgress(false));
  };

  const assigneeLimit = createMemo(() => profile.latest.getPermission(Permissions.TaskAssigneeLimit));

  const displayDate = (date: () => Date | null) => {
    const _date = date();

    if (!_date) {
      return t('task-editor none');
    }

    return t('task plan-date', { date: _date }, hasNoTime(_date) ? {
      hour: undefined,
      minute: undefined,
    } : {});
  };

  const cronDialog = useDialog('modal');
  const cronDialogRow = useDialog('modal');

  const repetitionList = [undefined, 'year', 'month', 'weekday', 'day'] satisfies (RepetitionType | undefined)[];

  const isRepSelected = createSelector(selectedRepetition[0]);

  createRenderEffect(on(() => get(planDate), date => {
    if (!date || hasNoTime(date)) {
      set(selectedRepetition, undefined);
      set(planCron, undefined);
    }

    if (date && get(selectedRepetition)) {
      set(planCron, dateToCron(date, get(selectedRepetition)));
    }
  }));

  createRenderEffect(on(() => get(selectedRepetition), (repetition) => {
    const date = get(planDate);

    if (date && repetition) {
      set(planCron, dateToCron(date, repetition));
    }
  }, { defer: true }));

  const isObserver = createMemo(() => {
    const currentUserId = WebApp.initDataUnsafe.user?.id;

    if (!currentUserId || !props.task) {
      return false;
    }

    const { assignee, coassignees, author } = props.task;
    const taskRelatedUsers = [assignee, ...coassignees, author]
      .filter((a): a is ClientUser => typeof a !== 'undefined');

    return taskRelatedUsers.every(a => a.userId !== currentUserId);
  });

  const isAdmin = createMemo(() => {
    const currentUserId = WebApp.initDataUnsafe.user?.id;

    if (!currentUserId || !currentProjectUsers) {
      return false;
    }

    return currentProjectUsers.latest.some(u => (
      u.userId === currentUserId
      && [ClientUserStatus.Administrator, ClientUserStatus.Creator].includes(u.status!)
    ));
  });

  const planDateDialog = useDialog('modal');
  const [,setPlanDateDialog] = planDateDialog;

  const uploadProgress = new WeakMap<File, Signal<number | undefined>>();
  const uploadAbort = new WeakMap<File, () => true | void>();
  const filesToBeDeleted: ClientTaskFile[] = [];

  const combinedFiles = () => ([] as Array<DisplayableFile>)
    .concat(get(files))
    .concat(getFormFiles(formData));

  const showFilePreview = createSignal<number>();

  let fileContainer!: HTMLDivElement;

  createEffect(() => {
    if (typeof get(showFilePreview) === 'number' && isMobile() && window.scrollY === 0) {
      window?.scrollTo({ behavior: 'smooth', top: 1 });
    }
  });

  let checkAnchor!: HTMLDivElement;

  const editingDescription = createSignal(false);
  const textPatterns = new RegExp(`${linkRegexp.source}|${checkRegexp.source}`, 'gmi');
  const linkMark = useLinkMarker();
  const [checklistMark, checks, resetChecks] = useChecklistMarker({
    textTemplate: description,
    props: { children: text => linkMark.mark(text) },
  });

  const textMark = new Marker((match, str = match()) => {
    if (!checkRegexp.test(str))
      return linkMark.mark(str);

    return checklistMark(match);
  }, {
    pattern: textPatterns,
    dependencies: () => {
      get(editingDescription);
      resetChecks();
    },
  });

  function addCheck() {
    set(expandedDescription, true);
    set(description, old => {
      return old + (old === '' ? '' : '\n') + `[ ]  `;
    });
  }

  // TODO: debug the double-navigation problem deeper to provide a serious fix
  let isNavigating = false;

  return <MultiProvider values={[
    [FullTaskContext, () => props.task],
    [PreviewContext, { show: (file) => set(showFilePreview, file) } satisfies FilePreviewController],
  ]}>
    <PreviewHost files={combinedFiles()}
      index={showFilePreview}
    />

    <Suspense>
      <Header
        id={props.task?.id}
        text={getTitle()}
        icon={
          <ItemIcon
            url={source.latest.icon}
            name={source.latest.id}
            type={source.latest.type}
            title={source.latest.name}
            id={source.latest.id}
          />
        }
      ></Header>
    </Suspense>

    <main class="= py-3 px-4 flex flex-col gap-4"
      style={{ 'min-height': get(files).length > 0 ? 'calc(100vh - 43px)' : undefined }}
    >
      <div class="= flex flex-col bg-tg_bg rounded-3 relative">
        <div class="= flex items-top">
          <div class="= flex items-top w-14">
            <Show when={isObserver()}
              fallback={
                <Checkbox class="= w-full h-12"
                  labelClass={isFormDisabled() ? 'opacity-70 filter-grayscale!' : ''}
                  model={isCompleted}
                  disabled={isRequestInProgress() || isCheckboxDisabled() || !props.task}
                  onClick={submitTaskComplete}
                />
              }
            >
              <div class="= relative w-full h-12 [&_*]:cursor-initial!">
                <Checkbox class="= w-full h-12"
                  disabled />
                <ChevronCheck class="= absolute fill-tg_hint top-5 left-6.5 " />
              </div>
            </Show>
          </div>
          <div class="= relative py-3 ltr:pr-2 rtl:pl-2 flex-grow"
            style="max-width:calc(100% - 56px)"
          >
            <textarea class="= absolute app-text-body-regular-stable w-full h-full p-0 ltr:pr-5 rtl:pl-5 overflow-hidden resize-none"
              use:model={title}
              placeholder={t('task-editor title-placeholder')}
              name="task-title"
              id="task-title"
              rows="1"
              disabled={isFormDisabled()}
            />
            <div class="= app-text-body-regular-stable w-full p-0 ltr:pr-8 rtl:pl-8 resize-none whitespace-pre-wrap"
              style="visibility:hidden"
              aria-hidden="true"
            >
              {get(title) + '\n'}
            </div>
          </div>
        </div>
        <div class="= flex items-top">
          <div class="= min-w-14" />
          <div class="= relative pb-3 flex-grow"
            style="max-width:calc(100% - 56px)"
            ref={checkAnchor}
          >
            <pre
              class="= absolute m-0 cursor-text app-text-subheadline-stable font-inherit! w-full h-full p-0 ltr:pr-2 rtl:pl-2
              overflow-hidden user-select-auto z-2 pointer-events-none text-wrap"
              classList={{
                'max-h-30': !get(expandedDescription),
                'hidden': get(editingDescription),
              }}
              ref={setDescriptionHeight}
            >
              <Show when={get(description)} fallback={<span class="= c-tg_hint">{t('task-editor description-placeholder')}</span>}>
                {textMark.mark(get(description))}
              </Show>
            </pre>
            <textarea class="= absolute app-text-subheadline-stable w-full h-full p-0 ltr:pr-2 rtl:pl-2 overflow-hidden resize-none"
              disabled={isFormDisabled()}
              use:model={description}
              placeholder={t('task-editor description-placeholder')}
              classList={{ 'max-h-30': !get(expandedDescription), 'opacity-0': !get(editingDescription) }}
              ref={setDescriptionHeight}
              onFocusIn={() => set(editingDescription, set(expandedDescription, true))}
              onFocusOut={() => set(editingDescription, false)}
              name="task-description"
              id="task-description"
              rows="1" />
            {/* LIMIT: 5000 symbols */}
            <div class="= app-text-subheadline-stable w-full p-0 ltr:pr-2 rtl:pl-2 overflow-hidden whitespace-pre-wrap"
              classList={{ 'max-h-30': !get(expandedDescription) }}
              style="visibility:hidden"
              aria-hidden="true"
              ref={el => createRenderEffect(on(() => props.task, () => setDescriptionHeight(el)))}
            >
              {get(description) + '\n'}
            </div>
            <Show when={!get(expandedDescription) && get(descriptionHeight) > minSetDescriptionHeight}>
              <span class="= absolute bottom-2.5 ltr:right-2 rtl:left-2 ltr:pr-2 rtl:pl-2 ltr:pl-6 rtl:pr-6 bg-tg_bg c-tg_link cursor-pointer z-3"
                style="background:linear-gradient(90deg, transparent 0%, var(--tg-theme-bg-color, var(--default-tg-theme-bg-color)) 30% 100%"
                onClick={() => set(expandedDescription, true)}
              >
                {t('task-editor description-more')}
              </span>
            </Show>
            <Show when={get(expandedDescription) || checks().length === 0}>
              <div role="button" class="= absolute ltr:left--9.5 rtl:right--9.5 bottom-2.5 opacity-80 cursor-pointer"
                onClick={addCheck}
              >
                <Show when={checks().length > 0}>
                  <div class="= absolute c-tg_hint ltr:right--0.5 rtl:left--0.5 bottom-[-4.5px]">+</div>
                </Show>
                <ListIcon class="= fill-tg_hint" />
              </div>
            </Show>
          </div>
        </div>

        <Show when={!isInline() || combinedFiles().length > 0}>
          <FilesList openPreview={(file) => set(showFilePreview, file)}
            ref={fileContainer}
            limit={maxFileAmount().limit}
            maxSize={maxFileSize().limit}
            formData={formData}
            files={files}
            disabled={isFilesDisabled()}
            onDisabled={() => navigate('/subscribe')}
            uploadProgress={uploadProgress}
            onRemoveFile={(file) => {
              if (file instanceof ClientTaskFile) {
                filesToBeDeleted.push(file);
                return;
              }

              const abort = uploadAbort.get(file);

              if (!abort) {
                uploadAbort.set(file, () => true);
              } else {
                abort();
              }
            }}
          >
            <Show when={!isRequestInProgress()}
              fallback={<Loader />}
            >
              <ProBadge when={!profile.latest.isPro} />
            </Show>
          </FilesList>
        </Show>
      </div>
      <div class="= flex flex-col bg-tg_bg rounded-3 overflow-hidden">
        <PlanDateSelect planDate={planDate}
          disabled={isPlanDateDisabled()}
          dialogControl={planDateDialog}
          bottomChildren={
            (time, date) =>
            <div class="= flex items-center px-5 mt--2 mb-2" onClick={isPlanDateDisabled() ? undefined : () => cronDialog[1](!!get(time))}
              classList={{ 'cursor-pointer': !isPlanDateDisabled() }}
            >
              {/* <div class="= min-w-14 flex items-top justify-center">
                <Repeat
                  classList={{
                    'fill-tg_hint!': !selectedRepetition.canUndo() || !get(planDate),
                    'fill-tg_button': selectedRepetition.canUndo(),
                  }}
                />
              </div> */}

              <div class="= flex flex-grow items-center">
                <p class="= app-text-subheadline flex-grow ltr:text-left rtl:text-right m-0"
                  classList={{ 'c-tg_hint!': !get(time) || isPlanDateDisabled() }}
                >
                  {t('task plan-cron-info')}
                </p>
                <p class="= app-text-subheadline-stable c-tg_button m-0 py-3"
                  classList={{
                    'c-tg_hint!': !get(date) || !selectedRepetition.canUndo() || !get(time) || isPlanDateDisabled(),
                  }}
                >
                  <Show when={get(time)}
                    fallback={tButton('datepicker time')}
                  >
                    {t(`task plan-cron repeat-${String(get(selectedRepetition)) as RepetitionType | 'undefined'}-short`, {
                      'task plan-cron repetition-short': [get(selectedRepetition)],
                      [`task plan-cron ${get(selectedRepetition)}`]: [getPlanDateWith(time, date)],
                      'task plan-cron time': [getPlanDateWith(time, date)],
                      'task plan-cron weekday': [getPlanDateWith(time, date)],
                    })}
                  </Show>
                </p>
              </div>

              <Dialog dialogParams={cronDialog}
                class="= fixed bg-tg_bg rounded-2 b-0 p-2 outline-none pb-3"
              >
                <List each={repetitionList} skipFilter>
                  {(value) => <List.Item simple
                    onClick={(e) => (e.stopPropagation(), cronDialog[1](false), set(selectedRepetition, value))}
                    right={
                      <Checkmark class="= fill-tg_button ml-2 opacity-0"
                        classList={{ 'opacity-100': isRepSelected(value) }}
                      />
                    }
                  >
                    <span classList={{ 'c-tg_button': !value }}>
                      {t(`task plan-cron repeat-${String(value) as RepetitionType | 'undefined'}`, {
                        'task plan-cron repetition': [value],
                        'task plan-cron repetition-short': [value],
                        'task plan-cron time': [getPlanDateWith(time, date)],
                        'task plan-cron weekday': [getPlanDateWith(time, date)],
                        [`task plan-cron ${value}`]: [getPlanDateWith(time, date)],
                      })}
                    </span>
                  </List.Item>}
                </List>
              </Dialog>
            </div>
          }
        >
          <div class="= flex items-center">
            <div class="= min-w-14 flex items-top justify-center">
              <Calendar
                classList={{
                  'fill-tg_hint': !planDate.canUndo(),
                  'fill-tg_button': planDate.canUndo(),
                }}
              />
            </div>
            <div class="= flex flex-grow items-center b-b-1 b-b-solid b-b-border ltr:pr-2 rtl:pl-2">
              <p class="= app-text-subheadline flex-grow ltr:text-left rtl:text-right m-0"
                classList={{
                  'c-tg_hint!': isPlanDateDisabled(),
                }}
              >
                {t('task plan-date-info')}
              </p>
              <p class="= app-text-subheadline-stable m-0 py-3 px-1"
                classList={{
                  'c-urgent': isDateInPast(get(planDate)),
                  'c-tg_button': !isDateInPast(get(planDate)),
                  'c-tg_hint': !get(planDate) || !planDate.canUndo(),
                }}
              >
                {displayDate(planDate[0])}
              </p>
              <Show when={!isPlanDateDisabled()}>
                <ListArrow class="= overflow-initial fill-tg_hint" />
              </Show>
            </div>
          </div>
        </PlanDateSelect>

        <div class="= flex items-center" onClick={() => isFormDisabled() || cronDialogRow[1](hasTime(get(planDate))) || setPlanDateDialog(true)}
          classList={{ 'cursor-pointer': !isPlanDateDisabled() }}
        >
          <div class="= min-w-14 flex items-top justify-center">
            <Repeat
              classList={{
                'fill-tg_hint!': !selectedRepetition.canUndo() || !get(planDate),
                'fill-tg_button': selectedRepetition.canUndo(),
              }}
            />
          </div>
          <div class="= flex flex-grow items-center ltr:pr-2 rtl:pl-2">
            <p class="= app-text-subheadline flex-grow ltr:text-left rtl:text-right m-0"
              classList={{
                'c-tg_hint!': isPlanDateDisabled(),
              }}
            >
              {t('task plan-cron-info')}
            </p>
            <p class="= app-text-subheadline-stable c-tg_button m-0 py-3 px-1"
              classList={{
                'c-tg_hint!': !get(planDate) || !selectedRepetition.canUndo(),
              }}
            >
              <Show when={hasTime(get(planDate))}
                fallback={tButton('datepicker time')}
              >
                {t(`task plan-cron repeat-${String(get(selectedRepetition)) as RepetitionType | 'undefined'}-short`, {
                  'task plan-cron repetition-short': [get(selectedRepetition)],
                  [`task plan-cron ${get(selectedRepetition)}`]: [get(planDate)],
                  'task plan-cron time': [get(planDate)],
                  'task plan-cron weekday': [get(planDate)],
                })}
              </Show>
            </p>
            <Show when={hasTime(get(planDate))}>
              <ListArrow class="= overflow-initial fill-tg_hint" classList={{ }} />
            </Show>
          </div>
        </div>

        <Dialog dialogParams={cronDialogRow}
          class="= fixed bg-tg_bg rounded-2 b-0 p-2 outline-none pb-3"
        >
          <List each={repetitionList} skipFilter>
            {(value) => <List.Item simple
              onClick={() => (cronDialogRow[1](false), set(selectedRepetition, value))}
              right={
                <Checkmark class="= fill-tg_button ml-2 opacity-0"
                  classList={{ 'opacity-100': isRepSelected(value) }}
                />
              }
            >
              <span classList={{ 'c-tg_button': !value }}>
                {t(`task plan-cron repeat-${String(value) as RepetitionType | 'undefined'}`, {
                  'task plan-cron repetition': [value],
                  'task plan-cron repetition-short': [value],
                  [`task plan-cron ${value}`]: [get(planDate)],
                  'task plan-cron time': [get(planDate)],
                })}
              </span>
            </List.Item>}
          </List>

          {/* <div class="= bg-border w-full h-[1px] z-2 my-4"/>

          <TimeInput timeLabel={tButton('datepicker time')} timeModel={timeModel} /> */}
        </Dialog>
      </div>

      <div class="= flex flex-col bg-tg_bg rounded-3 overflow-hidden">
        <DueDateSelect dueDate={dueDate}
          disabled={isDueDateDisabled()}
        >
          <div class="= relative flex items-center min-h-11"
            classList={{
              'cursor-not-allowed': isDueDateDisabled(),
            }}
          >
            <div class="= min-w-14 flex items-top justify-center">
              <Show when={!get(dueDate)}
                fallback={
                  <FlagFilled
                    classList={{
                      'fill-tg_hint!': isDueDateDisabled(),
                      'fill-tg_hint': !dueDate.canUndo(),
                      'fill-urgent': !!get(dueDate) && dueDate.canUndo(),
                      'fill-tg_button': !get(dueDate) && dueDate.canUndo(),
                    }}
                  />
                }
              >
                <Flag
                  classList={{
                    'fill-tg_hint!': isDueDateDisabled(),
                    'fill-tg_hint': !dueDate.canUndo(),
                    'fill-urgent': !!get(dueDate) && dueDate.canUndo(),
                    'fill-tg_button': !get(dueDate) && dueDate.canUndo(),
                  }}
                />
              </Show>
            </div>
            <div class="= flex flex-grow items-center ltr:pr-2 rtl:pl-2">
              <p class="= app-text-subheadline flex-grow ltr:text-left rtl:text-right m-0"
                classList={{
                  'c-tg_hint': isDueDateDisabled(),
                }}
              >
                {t('task due-date-info')}
              </p>

              <Show when={canUseDueDate() || get(dueDate)}
                fallback={
                  <ProBadge class="= ltr:pr-2 rtl:pl-2" />
                }
              >
                <p class="= app-text-subheadline-stable m-0 py-3 px-1"
                  classList={{
                    'c-urgent': isDateInPast(get(dueDate)),
                    'c-tg_button': !isDateInPast(get(dueDate)),
                    'c-tg_hint': !get(dueDate),
                  }}
                >
                  {displayDate(dueDate[0])}
                </p>
              </Show>
              <Show when={!isDueDateDisabled()}>
                <ListArrow class="= overflow-initial fill-tg_hint" />
              </Show>
            </div>
          </div>
        </DueDateSelect>
      </div>

      <UsersContext.Provider value={selectedProjectUsers}>
      <div class="= flex flex-col bg-tg_bg rounded-3">
        <ProjectSelect project={project}
          disabled={isProjectDisabled()}
        >
          {(onClick, isSelected, text) => <div role="button" class="= flex items-center cursor-pointer overflow-hidden"
            aria-disabled={isProjectDisabled()}
            onClick={!isProjectDisabled() ? onClick : undefined}
            classList={{ 'cursor-not-allowed!': isProjectDisabled() }}
          >
            <div class="= min-w-14 flex items-top justify-center overflow-initial">
              <Project class="= overflow-initial"
                classList={{
                  'fill-tg_hint': !project.canUndo() || isProjectDisabled(),
                  'fill-tg_button': project.canUndo() && !isProjectDisabled(),
                }}
              />
            </div>
            <div class="= flex flex-grow items-center ltr:pr-2 rtl:pl-2 overflow-hidden"
              classList={{ 'b-b-1 b-b-solid b-b-border': isPublicProject() }}
            >
              <p class="= app-text-subheadline flex-grow ltr:text-left rtl:text-right m-0"
                classList={{
                  'c-tg_text': !isProjectDisabled(),
                  'c-tg_hint': isProjectDisabled(),
                }}
              >
                {t('task project')}
              </p>
              <p class="= app-text-subheadline-stable m-0 ltr:ml-4 rtl:mr-4 py-3 px-1 text-ellipsis whitespace-nowrap overflow-hidden"
                classList={{
                  'c-tg_text': project.canUndo() && !isProjectDisabled(),
                  'c-tg_hint': !project.canUndo() || isProjectDisabled(),
                }}
              >
                {text}
              </p>
              <Show when={!isProjectDisabled()}>
                <ListArrow class="= overflow-initial fill-tg_hint" />
              </Show>
            </div>
          </div>}
        </ProjectSelect>
        <Show when={(isPublicProject() || !!props.task?.fromInline || get(assignees).length > 0) && !isInline()}
          fallback={
            <Show when={isInline() || props.task?.fromInline}>
              <InlineAssignee />
            </Show>
          }
        >
          <Show when={props.task}>
            <UserSelect users={author}
              title={t('task author')}
              limit={1}
              projectId={get(project).id}
              projectIcon={<ItemIcon title={get(project).name} url={get(project).icon} />}
              disabled={isAuthorDisabled()}
              disallowEmpty
            >
              {(onClick) => <div role="button" class="= flex items-center cursor-pointer overflow-hidden min-h-11"
                classList={{
                  'cursor-not-allowed!': isAuthorDisabled(),
                }}
                onClick={isAuthorDisabled() ? undefined : onClick}
              >
                <div class="= min-w-14 flex items-top justify-center overflow-initial">
                  <ReporterOutlined class="= overflow-initial"
                    classList={{
                      'fill-tg_hint': !author.canUndo() || isAuthorDisabled(),
                      'fill-tg_button': author.canUndo() && !isAuthorDisabled(),
                    }}
                  />
                </div>
                <div class="= flex flex-grow items-center b-b-1 b-b-solid b-b-border ltr:pr-2 rtl:pl-2 gap-4 overflow-hidden">
                  <p class="= app-text-subheadline flex-grow ltr:text-left rtl:text-right m-0"
                    classList={{
                      'c-tg_hint': isAuthorDisabled(),
                    }}
                  >
                    {t('task author')}
                  </p>
                  <p class="= app-text-subheadline-stable flex items-center m-0 py-2.25 gap-2 px-1 text-ellipsis whitespace-nowrap overflow-hidden">
                    <InitialsAvatar user={get(author)[0]} small />
                    <span class="= text-ellipsis whitespace-nowrap overflow-hidden"
                      classList={{
                        'c-tg_hint': !author.canUndo() || isAuthorDisabled(),
                        'c-tg_button': author.canUndo() && !isAuthorDisabled(),
                      }}
                    >
                      {get(author)[0]?.title}
                    </span>
                  </p>
                  <Show when={!isAuthorDisabled()}>
                    <ListArrow class="= overflow-initial fill-tg_hint ltr:ml--4 rtl:mr--4" />
                  </Show>
                </div>
              </div>}
            </UserSelect>
          </Show>
          <Show when={get(assignees).length > 0 || !props.task?.fromInline}
            fallback={<InlineAssignee />}
          >
            <UserSelect users={assignees}
              title={t('task assignee')}
              limit={assigneeLimit().limit ?? undefined}
              projectId={get(project).id}
              projectIcon={<ItemIcon title={get(project).name} url={get(project).icon} />}
              disabled={isAssigneeDisabled()}
            >
              {(onClick) => <div role="button" class="= flex items-center cursor-pointer overflow-hidden min-h-11"
                onClick={isAssigneeDisabled() ? undefined : onClick}
                classList={{
                  'cursor-not-allowed!': isAssigneeDisabled(),
                }}
              >
                <div class="= min-w-14 h-11 flex items-center justify-center overflow-initial"
                  style="align-self: start"
                >
                  <AssigneeOutlined class="= overflow-initial"
                    classList={{
                      'fill-tg_hint': !assignees.canUndo() || isAssigneeDisabled(),
                      'fill-tg_button': assignees.canUndo() && !isAssigneeDisabled(),
                    }}
                  />
                </div>
                <div class="= grid gap-x-2 flex-grow overflow-hidden"
                  style={{
                    'grid-template-columns': '[text] 1fr [assignee] auto',
                  }}
                >
                  <For each={get(assignees)}
                    fallback={
                      <AssigneeRow showArrow>
                        {/* <Show when={!isObserver()} fallback={
                          <span class="= c-tg_hint">
                            {t('task-editor none')}
                          </span>
                        }> */}
                          <span class="= c-tg_hint">
                            {t('task-editor add-assignee')}
                          </span>
                        {/* </Show> */}
                      </AssigneeRow>
                    }
                  >
                    {(assignee, index) => <AssigneeRow assignee={assignee} showArrow={index() === 0} hideText={index() !== 0} />}
                  </For>
                </div>
              </div>}
            </UserSelect>
          </Show>
        </Show>
      </div>
      </UsersContext.Provider>

      <Suspense>
        <Show when={props.task}>
          {task => <HistoryList taskId={task().id} />}
        </Show>
      </Suspense>

      <Show when={props.task && (isDiscussEnabled() || isDeleteEnabled())}>
        <div class="= grid gap-2"
          style={{
            'grid-template-columns': `repeat(${Number(isCalendarAttachEnabled()) + Number(isDiscussEnabled()) + 1}, 1fr)`,
          }}
        >
          <button class="= bg-tg_bg! rounded-3 disabled:opacity-90! c-urgent flex flex-col items-center px-4 py-3 gap-1"
            onClick={deleteTaskOnClick}
            disabled={!isDeleteEnabled()}
          >
            <Show when={!isDeleting()} fallback={<Loader />}>
              <Trash class="= fill-urgent" classList={{ 'fill-tg_hint!': !isDeleteEnabled() }} />
            </Show>
            <span class="= app-text-body-s c-urgent text-center flex-grow inline-flex items-center" classList={{ 'c-tg_hint!': !isDeleteEnabled() }}>
              {t('delete button-text')}
            </span>
          </button>

          <Show when={isCalendarAttachEnabled()}>
            <a class="= bg-tg_bg! rounded-3 disabled:opacity-90! c-urgent flex flex-col items-center px-4 py-3 gap-1"
              href={`${resolvedBackendUrl}/api/tasks/${props.task?.id}/ical`}
              target={(isMobile() || isMac()) ? '_blank' : undefined}
              download={(props.task?.title ?? 'task') + '.ics'}
              onClick={e => {
                if (isMobile()) {
                  e.preventDefault();
                  window.open(`${resolvedBackendUrl}/api/tasks/${props.task?.id}/ical`, '_blank');
                }
              }}
            >
              <Calendar class="= fill-tg_button scale-130 mt-1" />
              <span class="= app-text-body-s c-tg_button text-center flex-grow inline-flex items-center">
                {t('ical button-text')}
              </span>
            </a>
          </Show>

          <Show when={isDiscussEnabled()}>
            <button class="= bg-tg_bg rounded-3 flex flex-col items-center px-4 py-3 gap-1"
              onClick={postTaskToChat}
            >
              <Chat class="= fill-tg_button" />
              <span class="= c-tg_button app-text-body-s text-center flex-grow inline-flex items-center">
                {t('discuss button-text')}
              </span>
            </button>
          </Show>
        </div>
      </Show>
    </main>

    <MainButton text={!props.task ? t('task-editor button-create') : t('task button-save')}
      showProgress={isRequestInProgress()}
      disabled={isRequestInProgress() || isFormDisabled() || (get(title).length === 0)}
      onClick={() => submitTask()}
    />
    <Show when={WebApp.platform === 'unknown'}>
      <button class="= w-full mt-2 rounded-2 p-3 c-tg_button_text"
        onClick={() => submitTask()}
        disabled={isRequestInProgress() || isFormDisabled() || (get(title).length === 0)}
      >
        {!props.task ? t('task-editor button-create') : t('task button-save')}
      </button>
    </Show>
  </MultiProvider>;

  function isCalendarAttachEnabled(): boolean {
    return !!props.task;
  }

  function InlineAssignee() {
    return <UserSelect users={currentContact}
      title={t('task assignee')}
      disabled
    >
      {() => <div role="button" class="= flex items-center cursor-pointer overflow-hidden min-h-11"
        classList={{
          'cursor-not-allowed!': true,
        }}
      >
        <div class="= min-w-14 h-11 flex items-center justify-center overflow-initial"
          style="align-self: start"
        >
          <AssigneeOutlined class="= overflow-initial"
            classList={{
              'fill-tg_hint': !assignees.canUndo() || true,
              'fill-tg_button': assignees.canUndo() && !true,
            }} />
        </div>
        <div class="= grid gap-x-2 flex-grow overflow-hidden"
          style={{
            'grid-template-columns': '[text] 1fr [assignee] auto',
          }}
        >
          <AssigneeRow assignee={contact} amount={1} />
        </div>
      </div>}
    </UserSelect>;
  }

  function navigateBack(): void {
    if (isInline() || isNavigating) {
      return;
    }

    isNavigating = true;

    return runWithOwner(owner, () => {
      try {
        navigate(-1);
      } catch {
        isNavigating = false;
      }
    });
  }

  function getTitle(): string {
    return source.latest.type === ProjectType.Dynamic && !isArea(source.latest)
      ? tGroup('group-name', source.latest.id)
      : (source.latest ?? defaultProject).name;
  }

  function isFilesDisabled(): boolean | undefined {
    return !canUseFiles() || isFormDisabled();
  }

  function isAssigneeDisabled(): boolean | undefined {
    return (!isPrivateProject() && !(isUserAuthor() || isAdmin())) || isFormDisabled() || isInline() || (get(project).id === defaultProject.id);
  }

  function isAuthorDisabled(): boolean | undefined {
    return !(isUserAuthor() || isAdmin()) || isFormDisabled() || (get(project).id === defaultProject.id);
  }

  function isProjectDisabled(): boolean | undefined {
    return !isUserAuthor() || (!isUserAuthor() && isAdmin()) || isFormDisabled() || isInline();
  }

  function isPlanDateDisabled(): boolean | undefined {
    return isFormDisabled();
  }

  function isDiscussEnabled() {
    return !isFormDisabled() && !isRequestInProgress();
  }

  function isDeleteEnabled() {
    return !isFormDisabled() && !isRequestInProgress() && !isDeleting() && (isUserAuthor() || isAdmin());
  }

  function getPlanDateWith(time: Signal<string>, _date: Signal<Date | null>) {
    const date = get(_date);

    return date && withTime(date, ...get(time).split(':').map(Number));
  }

  function AssigneeRow(props: ParentProps<{
    assignee?: ClientUser;
    showArrow?: boolean;
    hideText?: boolean;
    amount?: number;
  }>) {
    return <>
      <p class="= app-text-subheadline flex items-center flex-grow ltr:text-left rtl:text-right m-0"
        classList={{
          'c-tg_hint': isAssigneeDisabled(),
          'mt--1.5': !props.showArrow,
          'opacity-0': !!props.hideText,
        }}
      >
        {t('task assignee', props.amount ?? get(assignees).length)}
      </p>
      <div class="= flex items-center gap-4 overflow-hidden ltr:pr-2 rtl:pl-2 justify-between"
        classList={{
          'mt--1.5': !props.showArrow,
        }}
      >
        <p class="= app-text-subheadline-stable flex items-center m-0 py-2.25 ltr:pl-1 rtl:pr-1 gap-2 text-ellipsis whitespace-nowrap overflow-hidden"
          classList={{
            'c-tg_text': assignees.canUndo() && !isAssigneeDisabled(),
            'c-tg_hint': !assignees.canUndo() || isAssigneeDisabled(),
          }}
        >
          <Show when={!props.children} fallback={props.children}>
            <Show when={hasSomeCompletedUsers()}>
              <Checkmark class="= fill-tg_button min-w-6"
                classList={{ 'opacity-0': !hasCompletedTask(props.assignee) }}
              />
            </Show>
            <InitialsAvatar user={props.assignee} small
              classList={{ 'opacity-50': hasCompletedTask(props.assignee) }}
            />
            <span class="= text-ellipsis whitespace-nowrap overflow-hidden"
              classList={{ 'opacity-50': hasCompletedTask(props.assignee) }}
            >
              {props.assignee?.title}
            </span>
          </Show>
        </p>
        <Show when={props.showArrow && !isAssigneeDisabled()}
          fallback={<div class="= ltr:ml--3 rtl:mr--3"/>}
        >
          <ListArrow class="= overflow-initial fill-tg_hint ltr:ml--3 rtl:mr--3" />
        </Show>
      </div>
    </>;
  }

  function initialDate(): Date | null {
    return props.task ? props.task.planDate : (source.latest.id === 'g_tom' ? tomorrow() : today());
  }

  function isCheckboxDisabled(): boolean | undefined {
    return props.task?.completable ?? (
      (!props.task?.isCompleted && !isPrivateProject())
      || isCompletionInProgress()
    );
  }

  function isDueDateDisabled(): boolean | undefined {
    return (!isPrivateProject() && !(isUserAuthor() || isAdmin()))
      || isFormDisabled()
      || !canUseDueDate()
      || isFormDisabled();
  }

  function setDescriptionHeight(el: HTMLElement) {
    el.style.height = '1px';

    setTimeout(() => {
      if (el.scrollHeight < minSetDescriptionHeight) {
        el.style.height = '';
        return;
      }

      el.style.height = `${el.scrollHeight}px`;

      set(descriptionHeight, el.scrollHeight);

      if (el instanceof HTMLTextAreaElement) {
        el.style.height = '';
      } else {
        el.style.height = 'initial';
      }
    });
  }

  function submitTask(preventNavigation?: boolean) {
    setRequestInProgress(true);

    let taskId: string;

    return (
      props.task
      ? props.setTask(FullClientTask.fromRaw({
          ...props.task,
          title: get(title),
          description: get(description),
          isCompleted: get(isCompleted),
          author: get(author)[0] ?? undefined,
          dueDate: get(dueDate),
          planDate: (get(planCron) && get(planDate)) ? withTime(get(planDate)!) : get(planDate),
          planCron: get(selectedRepetition) ? get(planCron) : undefined,
          project: get(project),
          coassignees: get(assignees),
        }), false)
      : props.setTask(BaseClientTask.fromRaw({
          title: get(title),
          description: get(description),
          dueDate: get(dueDate),
          planDate: (get(planCron) && get(planDate)) ? withTime(get(planDate)!) : get(planDate),
          planCron: get(selectedRepetition) ? get(planCron) : undefined,
          coassignees: get(assignees),
          type: TaskType.Task,
          fromInline: isInline(),
        }), get(project).id)
    ).then(([refetchTask, task]) => {
      const newProject = get(project);
      const old = project.previous();

      if (newProject?.id !== old?.id) {
        refetchTask();
      }

      taskId = task?.id ?? props.task?.id ?? '';

      if (typeof taskId !== 'string' || !taskId) {
        return;
      }

      let messageId = task?.lastMessageId ?? props.task?.lastMessageId;

      return parallel(
        () => filesToBeDeleted.length > 0 ? deleteFiles(taskId, filesToBeDeleted.map(f => f.id), messageId) : undefined,
        async () => {
          if (formData.getAll('files').length <= 0) {
            return;
          }

          WebApp.BackButton.hide();

          try {
            let files = getFormFiles(formData);
            let first: File;

            if (!messageId) {
              [first, ...files] = files;

              const wrapper = await attachSingleFile(taskId, first);
              const response = typeof wrapper === 'string'
              ? wrapper
              : (typeof wrapper === 'object' && wrapper && 'json' in wrapper && typeof wrapper.json === 'function')
                ? await (wrapper)?.json()
                : undefined;

              messageId = response ? Number(response) : undefined;
              messageId = (typeof messageId === 'number' && isNaN(messageId)) ? undefined : messageId;
            }

            await Promise.all(
              files.map(file => attachSingleFile(taskId, file, messageId)),
            );

            setRequestInProgress(false);
          } catch (error) {
            WebApp.showAlert('Task created successfully, but some files failed to attach');
            console.error(error);

            setRequestInProgress(false);
          }
        },
      );
    })
    .catch(() => {
      // Inline mode - create task by id
      if (isInline()) {
        WebApp.showAlert('Task creation error; Please, try again.');
        return;
      }
    })
    .finally(() => {
      setRequestInProgress(false);

      // Inline mode - create task by id
      if (isInline() && taskId) {
        WebApp.switchInlineQuery(taskId);
        return;
      }

      [...formData.keys()].forEach(key => formData.delete(key));

      if (!preventNavigation) {
        navigateBack();
      }
    });

    async function attachSingleFile(taskId: string, file: File, messageId?: number) {
      const singleFile = new FormData();
      singleFile.append(formFieldName, file);

      if (uploadAbort.get(file)?.()) {
        return;
      }

      const [response, abort] = await attachFiles(
        taskId,
        singleFile,
        num => {
          set(uploadProgress.get(file), num);
        },
        messageId,
      );

      uploadAbort.set(file, abort);

      return await response;
    }
  }

  function isPrivateProject() {
    return !get(project) || (get(project)?.type === ProjectType.Private);
  }

  function isPublicProject() {
    return !get(project) || (get(project)?.type === ProjectType.Public);
  }

  function isUserAuthor() {
    return !props.task?.author || props.task?.author.userId === WebApp.initDataUnsafe.user?.id;
  }
}
